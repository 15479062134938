import React from 'react';
import './get.scss';
import './get-media.scss';

function Get() {
    return (
        <div className="wrapper_get">
            <span className="wrapper_name">What do you get?</span>
            <div className="get">
                {/* <div className="left_section">
                <div className="slideShow">
                    <video></video>
                </div>
                </div> */}
                <div className="description_section">
                    <div className="bonus">
                        <span className="nameOfBonus">15+ Matches Daily</span>
                        <span className="descriptionOfBonus">You will exponentially increase the number of matches without extra effort.
                            This will significantly boost your chances of finding suitable people.
                        </span>
                    </div>
                    <div className="bonus">
                        <span className="nameOfBonus">A new level of quality dating</span>
                        <span className="descriptionOfBonus">
                            You will notice an increase in the number of likes. Start chatting with women you truly find
                            attractive and meet those who are on your level or even higher. Our method will help you to
                            get more quality matches, leading to more interesting and satisfying connections.
                        </span>
                    </div>
                </div>
                <div className="description_section dis2">
                    <div className="bonus">
                        <span className="nameOfBonus">Date Smarter, Not Harder</span>
                        <span className="descriptionOfBonus">
                            The method is easy to use, and anyone is able to set it up.
                            You won’t need any premium Tinder subscriptions.
                        </span>
                    </div>
                    <div className="bonus">
                        <span className="nameOfBonus">A method for everyone!</span>
                        <span className="descriptionOfBonus">
                            Regardless of your city, country, or appearance, our method will work for you. It is universal
                            and effective for any Tinder user. You won’t need any premium subscriptions or paid features.
                            Just follow the simple steps outlined in our guide.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Get;