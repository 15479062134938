import React from 'react';
import './solution.scss';
import './solution-media.scss';


function Solution() {
    return (
        <div className="wrapper_solution">
            <div className="solution">
                <span className="main_item">
                    It's so frustrating! Who needs an extra expense of
                    upgrades and premium subscriptions?
                </span>
                <span className="item">
                    We understand how you feel. It seems like pro photos and pictures of
                    your friends' pets should work, but there are still few or even no matches.
                    It's exhausting. You've put in so much effort and maybe even money,
                    but the recipe for success still seems out of reach.
                </span>
                <span className="item">
                    We hear this time and time again in reviews about dating apps.
                    <b> The good news is, there is a solution!</b>
                </span>
                <span className="item">
                    Forget feeling frustrated – <b>We promise it works for every one </b>
                    of the millions of dating app users.
                </span>
                <span className="item">
                    The main problem is deciding how to spend your time with new acquaintances.
                </span>
                <span className="item">
                    <b>Are you ready for a flood of new connections?</b>
                </span>
            </div>
        </div>
    )
}

export default Solution;