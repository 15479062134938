export const QuestionsMocks = [
    {
        question: "How does this glitch work?",
        answer: "This method allows you to naturally increase your internal Tinder rating. We use knowledge of vulnerabilities in Tinder's ranking algorithm. By increasing your rating, your profile will be shown to new girls much more frequently. Additionally, we offer a profile optimization guide that can further increase the number of matches. Both methods can be used together or separately.",
    },
    {
        question: "What will I get exactly?",
        answer: "You will receive a detailed guide in the form of a downloadable PDF file, which provides step-by-step instructions necessary for increasing your Tinder rating. The guide includes detailed screenshots and explanations to help you easily follow each step.",
    },
    {
        question: "Will this work on my device?",
        answer: "Explanation of device compatibility.",
    },
    {
        question: "What happens after I pay?",
        answer: "After payment, you will get instant access to the guide in digital format. You will also receive an email with a download link, so you can save the guide and access it in the future. You will be able to start applying the method right away.",
    },
    {
        question: "Do you need my password?",
        answer: "No, we do not need any information about you. You will apply the method yourself by following the step-by-step instructions in the provided PDF file. This ensures that your confidential information remains secure.",
    },
    {
        question: "Will this hurt my account?",
        answer: " No, it is absolutely safe for your account. We do not violate Tinder's terms of use.",
    },
    {
        question: "Why is this better than buying a Tinder boost?",
        answer: "Why is this better than buying boosts and other premium features in Tinder? Boosts in Tinder only temporarily increase your profile's visibility. Maintaining a top ranking with boosts requires regular payments. Additionally, paid features often result in your profile being shown to users with low ratings. This means that your profile is seen by users who are less active and less likely to match. Our method will give you more matches, and they will be of higher quality. You get long-term results without the need for constant additional expenses.",
    },
    {
        question: "Can I contact you for more details?",
        answer: " Absolutely! You can contact us at any time to ask any questions you may have."
    }
]