import React from 'react';
import './confirmRemove.scss';
import './confirmRemove-media.scss';

const ConfirmDialog = ({ title, message, onConfirm, onCancel }) => {
    return (
        <div className="confirm_dialog_overlay" onClick={onCancel}>
            <div className="confirm_dialog" onClick={(e) => e.stopPropagation()}>
                <h2>{title}</h2>
                <p>{message}</p>
                <div className="confirm_dialog_buttons">
                    <button onClick={onConfirm}>Yes</button>
                    <button onClick={onCancel}>No</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmDialog;
