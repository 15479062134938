export const getUserLocation = async () => {
    try {
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) {
            throw new Error('Failed to fetch user location');
        }
        const data = await response.json();
        return data.currency;
    } catch (error) {
        console.error('Failed to fetch user location', error);
        throw error;
    }
};

export const fetchExchangeRates = async () => {
    try {
        const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
        if (!response.ok) {
            throw new Error('Failed to fetch exchange rates');
        }
        const data = await response.json();
        return data.rates;
    } catch (error) {
        console.error('Failed to fetch exchange rates', error);
        throw error;
    }
};

export const convertPrice = (price, targetCurrency, exchangeRates) => {
    const rate = exchangeRates[targetCurrency] || 1;
    return Math.round(price * rate);
};

export const applyDiscount = (price, discountPercentage) => {
    return Math.round(price * (1 - discountPercentage / 100));
};
