import { React, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './productCard.scss';
import './productCardModal-media.scss';
import iconClose from '../../home_page/img/iconClose.svg';
import ButtonAdd from '../../home_page/presentation/components/button-add/ButtonAdd';
import { useCart } from '../../cart/cart-context-section/Cart';

const Modal = ({ product, onClose }) => {

    const { name, description, prices, image, currencyCode } = product;
    const saving = prices.original - prices.discounted;

    const { addToCart, openCart } = useCart();

    const handleAddToCart = (product) => {
        addToCart(product);
        openCart();
        onClose();
    };


    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    return (
        <div className="product_modal_overlay" onClick={onClose}>

            <div className="product_modal" onClick={(e) => e.stopPropagation()}>
                <div className="product_modal_image">
                    <img src={image} alt={name} />
                    <button className="button_modal_close_2" onClick={onClose}>
                        <img src={iconClose} alt="Close" />
                    </button>
                </div>
                <div className="product_modal_details">
                    <div className="right_side">
                        <button className="button_modal_close" onClick={onClose}>
                            <img src={iconClose} alt="Close" />
                        </button>
                    </div>
                    <div className="left_side">
                        <span className="product_name">{name}</span>
                        <span className="product-description">{description}</span>
                        <div className="button-section">
                            <ButtonAdd
                                onClick={() => handleAddToCart(product)}
                            >
                                Add Product
                            </ButtonAdd>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
function ProductCardModal(product, onClose) {
    return ReactDOM.createPortal(Modal(product, onClose), document.querySelector("#cardModal"));
}

export default ProductCardModal;
