// import React from 'react';
// import { useInView } from 'react-intersection-observer';
// import Header from '../header/Header';
// import ProductDescription from '../product-description-section/ProductDescription';
// import Solution from '../solution-section/Solution';
// import Questions from '../questions-section/Questions';
// import Footer from '../footer/Footer';
// import AllProducts from '../all-products/AllProducts';
// import GeneralProduct from '../general-product-section/GeneralProduct';
// import Result from '../result-section/Result';
// import Get from '../get-section/Get';
// import './home_page.scss';
// import './home_page-media.scss';

// // import { CartProvider } from '../cart/CartContext';

// function Section({ children, id }) {
//     const { ref, inView } = useInView({
//         triggerOnce: true,
//         threshold: 0.2, 
//     });

//     return (
//         <div ref={ref} id={id} className={`fade-in-section ${inView ? 'visible' : ''}`}>
//             {children}
//         </div>
//     );
// }

// function HomePage() {
//     return (
//         <div className="home_page">
//             <div id="header"><Header /></div>
//             <Section id="product-description"><ProductDescription /></Section>
//             <Section id="solution"><Solution /></Section>
//             <Section id="result"><Result /></Section>
//             <Section id="get"><Get /></Section>
//             <Section id="all-products"><AllProducts /></Section>
//             <Section id="general-products"><GeneralProduct /></Section>
//             <Section id="questions"><Questions /></Section>
//             <div id="footer"><Footer /></div>
//         </div>
//     );
// };

// export default HomePage;

// src/modules/home_page/presentation/components/home-page/HomePage.js
import React from 'react';
import { useInView } from 'react-intersection-observer';
import Header from '../header/Header';
import ProductDescription from '../product-description-section/ProductDescription';
import Solution from '../solution-section/Solution';
import Questions from '../questions-section/Questions';
import Footer from '../footer/Footer';
import AllProducts from '../all-products/AllProducts';
import GeneralProduct from '../general-product-section/GeneralProduct';
import Result from '../result-section/Result';
import Get from '../get-section/Get';
import CartModal from '../../../../cart/cart-section/CartModal'; 
import { CartProvider, useCart } from '../../../../cart/cart-context-section/Cart';
import './home_page.scss';
import './home_page-media.scss';

function Section({ children, id }) {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.2, 
    });

    return (
        <div ref={ref} id={id} className={`fade-in-section ${inView ? 'visible' : ''}`}>
            {children}
        </div>
    );
}
  
function HomePage() {
    return (
        <CartProvider>
            <div className="home_page">
                <div id="header"><Header /></div>
                <Section id="product-description"><ProductDescription /></Section>
                <Section id="solution"><Solution /></Section>
                <Section id="result"><Result /></Section>
                <Section id="get"><Get /></Section>
                <Section id="all-products"><AllProducts /></Section>
                <Section id="general-products"><GeneralProduct /></Section>
                <Section id="questions"><Questions /></Section>
                <div id="footer"><Footer /></div>
                <CartContainer />
            </div>
        </CartProvider>
    );
}

const CartContainer = () => {
    const { isCartOpen, cartItems, removeFromCart, updateQuantity, closeCart } = useCart();

    return (
        <CartModal 
            isOpen={isCartOpen} 
            cartItems={cartItems} 
            removeFromCart={removeFromCart} 
            updateQuantity={updateQuantity} 
            onClose={closeCart} 
        />
    );
};

export default HomePage;

