import React, { useState } from 'react';
import './questions.scss';
import './questions-media.scss';
import iconDone from '../../../img/done.png';
import iconArrowDown from '../../../img/arrowDown.png';
import iconArrowUp from '../../../img/arrowUp.png';
import { QuestionsMocks } from '../../../mocks/questions.js';

function Questions() {
    const [expandedItems, setExpandedItems] = useState([]);

    const toggleExpand = (index) => {
        setExpandedItems((prev) => {
            if (prev.includes(index)) {
                return prev.filter((i) => i !== index);
            } else {
                return [...prev, index];
            }
        });
    };

    return (
        <div className="wrapper_questions">
            <div className="questions">
                <div className="main_item">
                    <span>Frequently asked questions</span>
                </div>
                {QuestionsMocks.map((item, index) => (
                    <div
                        className={`item ${expandedItems.includes(index) ? 'expanded' : ''}`}
                        onClick={() => toggleExpand(index)}
                        key={`question-${index}`}
                    >
                        <div className='itm'>
                            <img src={iconDone} alt="V" className="iconDone" />
                            <span>{item.question}</span>
                            <img
                                src={expandedItems.includes(index) ? iconArrowUp : iconArrowDown}
                                alt=""
                                className="iconArrow"
                            />
                        </div>
                        {expandedItems.includes(index) && (
                            <div className="answer">
                                <span>{item.answer}</span>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Questions;
