import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';
import './footer-media.scss';
import iconLogo from '../../../img/9.png';
import iconInstagram from '../../../img/iconInstagram.svg';
import iconFacebook from '../../../img/iconFacebook.svg';
import iconTikTok from '../../../img/iconTikTok.svg';
import PrivacyPolicyModal from '../../../../modal-windows/privacy-policy-modal/PrivacyPolicyModal';
import RefundPolicyModal from '../../../../modal-windows/refund-policy-modal/RefundPolicyModal';
import TermsOfServiceModal from '../../../../modal-windows/Terms-of-service-modal/TermsOfServiceModal';
import { useModal, handleReloadAndScrollUp } from '../../../../utils/modalUtils'; // Импорт утилит

function Footer() {
    const {
        isPrivacyPolicyOpen,
        isRefundPolicyOpen,
        isTermsOpen,
        openModal,
        closeModal,
    } = useModal(); // Использование утилитного хука

    const [email, setEmail] = useState('');

    const handleScrollTo = (id) => {
        window.scrollTo({ top: document.getElementById(id).offsetTop - 120, behavior: 'smooth' });
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        console.log('Email submitted: ', email);
        setEmail('');
    };

    return (
        <div className="wrapper_footer">
            <div className="footer">
                <div className="first_section">
                    <div className="logo_name">
                        <img className="item1" src={iconLogo} alt="Logo Icon" />
                    </div>
                    <div className="social_section">
                        <img src={iconInstagram} alt="instagram" className="item" />
                        <img src={iconFacebook} alt="facebook" className="item" />
                        <img src={iconTikTok} alt="tikTok" className="item" />
                    </div>
                </div>
                <div className="second_section">
                    <span className="item_gen">Quick links</span>
                    <span className="item" onClick={() => handleScrollTo('header')}>Home</span>
                    <span className="item" onClick={() => handleScrollTo('all-products')}>All product</span>
                    <span className="item" onClick={() => handleScrollTo('footer')}>Contact</span>
                </div>
                <div className="third_section">
                    <div className="input_email_section">
                        <span className="item_gen">Subscribe for discounts</span>
                        <span className="item">Be the first to get updates of new products, and discounts.</span>
                        <input
                            type="email"
                            placeholder="Email"
                            className="input_email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={handleKeyPress}
                        />
                        <button onClick={handleSubmit} className="submit_email">Submit</button>
                    </div>  
                </div>
            </div>
            <div className="privacy_policy_section">
                <ul className="table_privacy">
                    <li>
                        <Link 
                            to="/" 
                            className="link" 
                            onClick={handleReloadAndScrollUp}>
                            © 2024, Wizard's Swipe
                        </Link>
                    </li>
                    <li><span className="link" onClick={() => openModal('privacyPolicy')}>Privacy Policy</span></li>
                    <li><span className="link" onClick={() => openModal('refundPolicy')}>Refund Policy</span></li>
                    <li><span className="link" onClick={() => openModal('termsOfService')}>Terms of service</span></li>
                </ul>
            </div>
            <PrivacyPolicyModal isOpen={isPrivacyPolicyOpen} onClose={() => closeModal('privacyPolicy')} />
            <RefundPolicyModal isOpen={isRefundPolicyOpen} onClose={() => closeModal('refundPolicy')} />
            <TermsOfServiceModal isOpen={isTermsOpen} onClose={() => closeModal('termsOfService')} />
        </div>
    );
}

export default Footer;
