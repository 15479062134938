import React from 'react';
import './generalProduct.scss';
import './generalProduct-media.scss';
import tinderGlitch from '../../../img/tinderGuide.jpg';
import { products } from '../all-products/AllProducts';
import { useCart } from '../../../../cart/cart-context-section/Cart';
import ButtonAdd from '../button-add/ButtonAdd';

function GeneralProduct() {

    const { openCart, addToCart } = useCart();

    const handleGetTheGlitch = () => {
        const product = products.find(product => product.id === 2);
        if (product) {
            addToCart(product);
            openCart();
        }
    }

    return (
        <div className="wrapper_general_product">
            <div className="general_section">
                <div className="left_side">
                    <div className="left_small_section">
                        <span className="product_name">Devil's Matchbox: Profile Perfection Guide</span>
                        <div className="short_description">
                            <span>Unlock the secrets to creating an attractive profile with our guide
                                "Devil's Matchbox: Profile Perfection Guide," boosting your chances of successful
                                connections on dating platforms without the need for a premium subscription.
                            </span>
                        </div>
                        <div className="section_one">
                            <span className="item1">Instant Download</span>
                            <span className="item2">Lifetime access</span>
                        </div>
                        <div className="order_now">
                            <span>
                                <b>Don't miss out on this chance, </b>
                                place your order now and get our 'Texting God' method at no cost.
                            </span>
                        </div>
                        <div className="cart_section">
                            <ButtonAdd
                                onClick={handleGetTheGlitch}
                            >
                                Add Product
                            </ButtonAdd>
                        </div>
                    </div>
                    <div className="right_img_side">
                        <img src={tinderGlitch} alt="#" className="img" />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default GeneralProduct;