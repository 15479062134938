import {React, useEffect} from "react";
import './termsOfServiceModal.scss';
import iconClose from '../../home_page/img/iconClose.svg';

const TermsOfServiceModal = ({ isOpen, onClose }) => {

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);
    
    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal_overlay_terms" onClick={onClose}>
            <div className="modal_content_terms" onClick={(e) => e.stopPropagation()}>
                <button className="close_button_terms" onClick={onClose}>
                    <img src={iconClose} alt="" />
                </button>
                <h2>Terms of service</h2>
                <p>This is the Terms of service content.</p>
                <span>Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Distinctio enim soluta aliquid ipsa placeat tempore, labore
                    nesciunt delectus obcaecati, numquam hic nihil nemo! Illum
                    saepe voluptatem molestiae excepturi laudantium provident!
                </span>
                {/* Добавьте больше содержимого здесь по мере необходимости */}
            </div>
        </div>




    )
}

export default TermsOfServiceModal;