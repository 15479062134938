import React from 'react';
import './productDescription.scss';
import './productDescription-media.scss';
import screen from '../../../img/screen3W.webp';
import { products } from '../all-products/AllProducts';
import { useCart } from '../../../../cart/cart-context-section/Cart';

function ProductDescription() {

    const { openCart, addToCart } = useCart();

    const handleGetTheGlitch = () => {
        const product = products.find(product => product.id === 2);
        if (product) {
            addToCart(product);
            openCart();
        }
    }
    return (
        <div className="wrapper_product_description">
            <div className="product_description">
                <span className="text_section1">
                    Looks aren't the key to success on Tinder!

                </span>
                <span className="text_section2">
                    Tested on hundreds of guys across different countries.
                    Once your rating improves with our method, you'll see a
                    significant increase in matches. It all happens automatically.
                </span>
                <button className="button_get" onClick={handleGetTheGlitch}>Get the glitch</button>
                <div className="screenImg">
                    <img src={screen} alt="" />
                </div>
            </div>
        </div>
    )
}

export default ProductDescription;

