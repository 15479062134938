import React, { useState, useEffect } from 'react';
import './header.scss';
import './header_media.scss';
import { ReactComponent as Logo } from '../../../img/iconMatchBoxRed.svg';
import { ReactComponent as IconBasket } from '../../../img/iconBasketBlack.svg';
import iconClose from '../../../img/iconClose.svg';
import iconInstagram from '../../../img/iconInstagram.svg';
import iconFacebook from '../../../img/iconFacebook.svg';
import iconTikTok from '../../../img/iconTikTok.svg';
import { Link as ScrollLink, scroller as animateScroll } from 'react-scroll';
import { Link } from 'react-router-dom';
import { useCart } from '../../../../cart/cart-context-section/Cart';
import { products } from '../all-products/AllProducts'; // Импорт продуктов
import PrivacyPolicyModal from '../../../../modal-windows/privacy-policy-modal/PrivacyPolicyModal';
import RefundPolicyModal from '../../../../modal-windows/refund-policy-modal/RefundPolicyModal';
import TermsOfServiceModal from '../../../../modal-windows/Terms-of-service-modal/TermsOfServiceModal';
import { useModal, handleReloadAndScrollUp } from '../../../../utils/modalUtils';

function Header() {
    const [isShrink, setIsShrink] = useState(false);
    const [selectedLink, setSelectedLink] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isShadow, setShadow] = useState('');

    const { getTotalItems, openCart, addToCart } = useCart();
    const totalItems = getTotalItems();

    const {
        isPrivacyPolicyOpen,
        isRefundPolicyOpen,
        isTermsOpen,
        openModal,
        closeModal,
    } = useModal();

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setIsShrink(true);
        } else {
            setIsShrink(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (isMenuOpen === true) {
            document.body.style.overflow = 'hidden';

            return () => {
                document.body.style.overflow = 'unset';
            };
        }
    }, [isMenuOpen]);

    const handleScrollToFooter = () => {
        animateScroll.scrollTo('solution', {
            smooth: true,
            offset: -120,
            duration: 700
        });
    };

    const handleCloseMenu = () => {
        setIsMenuOpen(false);
        setShadow('');
    };

    const handleLinkClick = (link) => {
        setSelectedLink(link);
        handleCloseMenu();
    };

    const handleMenuOpen = () => {
        setIsMenuOpen(!isMenuOpen);
        setTimeout(() => {
            setShadow('50vw 0 10px 10px rgba(0, 0, 0, 0.5)');
        }, 150);
    };

    const handleBuyNow = () => {
        const product = products.find(product => product.id === 3);
        if (product) {
            addToCart(product);
            openCart();
        }
    };

    return (
        <div className="wrapper_top">
            <div className={`overlay ${isMenuOpen ? 'active' : ''}`} onClick={handleCloseMenu}></div>
            <div className="gradient">
                <div className="header">
                    <div className={`navigation_wrapper ${isShrink ? 'shrink' : ''} `}>
                        <button className={`burger-menu ${isMenuOpen ? 'active' : ''}`} onClick={() => handleMenuOpen()}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 50 50"
                                className={`iconBurger ${isShrink ? 'shrink' : ''}`}>
                                <path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 
                                L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z"></path>
                            </svg>
                        </button>
                        <div className={`nav_bar ${isShrink ? 'shrink' : ''} ${isMenuOpen ? 'active' : ''}`} style={{ boxShadow: isShadow }}>
                            <div className="wrapper_close_button">
                                <span className="menu">Menu</span>
                                <button className="close_button" onClick={handleCloseMenu}>
                                    <img src={iconClose} alt="" className="iconClose" />
                                </button>
                            </div>
                            <ScrollLink
                                to="header"
                                spy={true}
                                smooth={true}
                                offset={0}
                                duration={500}
                                className={`link ${selectedLink === 'header' ? 'selected' : ''} ${isMenuOpen ? 'open' : ''}`}
                                onClick={() => handleLinkClick('header')}
                            >
                                <span>Home</span>
                            </ScrollLink>
                            <ScrollLink
                                to="all-products"
                                spy={true}
                                smooth={true}
                                offset={-120}
                                duration={500}
                                className={`link ${selectedLink === 'all-products' ? 'selected' : ''}`}
                                onClick={() => handleLinkClick('all-products')}
                            >
                                <span>
                                    All product
                                </span>
                            </ScrollLink>
                            <ScrollLink
                                to="footer"
                                spy={true}
                                smooth={true}
                                offset={100}
                                duration={500}
                                className={`link ${selectedLink === 'contact' ? 'selected' : ''}`}
                                onClick={() => handleLinkClick('contact')}
                            >
                                <span>
                                    Contact
                                </span>
                            </ScrollLink>
                            <div className="burger_menu_social">
                                <img src={iconInstagram} alt="instagram" className="item" />
                                <img src={iconFacebook} alt="facebook" className="item" />
                                <img src={iconTikTok} alt="tikTok" className="item" />
                            </div>
                            <div className="privacy_policy_section">
                                <ul className="table_privacy">
                                    <li>
                                        <Link
                                            to="/"
                                            className="links"
                                            onClick={handleReloadAndScrollUp}>
                                            © 2024, Wizard's Swipe
                                        </Link>
                                    </li>
                                    <li><span className="links" onClick={() => openModal('privacyPolicy')}>Privacy Policy</span></li>
                                    <li><span className="links" onClick={() => openModal('refundPolicy')}>Refund Policy</span></li>
                                    <li><span className="links" onClick={() => openModal('termsOfService')}>Terms of service</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className={`logo ${isShrink ? 'shrink' : ''}`}>
                            <Logo className="iconLogo" />
                            <span className="item1 item">Match</span>
                            <span className="item2 item">Box</span>
                        </div>
                        <div className={`shopping_basket ${isShrink ? 'shrink' : ''}`} onClick={() => openCart()}>
                            <IconBasket className="iconBasket" />
                            {totalItems > 0 && <div className="cart-icon-badge">{totalItems}</div>}
                        </div>
                    </div>
                </div>
                <div className="wrapper_middle">
                    <div className="information_about_company">
                        <div className="company_name">PUMP UP YOUR DATES</div>
                        <div className="description">
                            <span>
                                This glitch allows anyone to generate insane amounts
                                of matches on Tinder, no matter what you look like,
                                and without any extra effort.
                            </span>
                        </div>
                        <div className="buttons">
                            <button className="button button_buy" onClick={handleBuyNow}>Buy Now</button>
                            <button className="button learn_more" onClick={handleScrollToFooter}>Learn more</button>
                        </div>
                    </div>
                </div>
            </div>
            <PrivacyPolicyModal isOpen={isPrivacyPolicyOpen} onClose={() => closeModal('privacyPolicy')} />
            <RefundPolicyModal isOpen={isRefundPolicyOpen} onClose={() => closeModal('refundPolicy')} />
            <TermsOfServiceModal isOpen={isTermsOpen} onClose={() => closeModal('termsOfService')} />
        </div>

    );
}

export default Header;
