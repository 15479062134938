// import picOfProduct1 from '../../../img/logo1.png';
import React, { useState, useEffect } from 'react';
import { useCart } from '../../../../cart/cart-context-section/Cart';
import './allProducts.scss';
import './allProducts-media.scss';
import { getUserLocation, fetchExchangeRates, convertPrice, applyDiscount } from '../../../../utils/Utils';
import ProductCardModal from '../../../../modal-windows/product-card/ProductCardModal';
import product1 from '../../../img/textScript.jpg';
import product2 from '../../../img/tinderGuide.jpg';
import product3 from '../../../img/bundle.jpg';
import ButtonAdd from '../button-add/ButtonAdd';

export const products = [
    { id: 1, name: 'Messaging Guide', price: { USD2: 20 }, image: product1, description: 'Unlock the secrets to successful communication with our help.This comprehensive guide will teach you proven strategies to craft the perfect messages, engage in meaningful conversations, and secure more dates effortlessly.' },
    { id: 2, name: 'Profile Guide', price: { USD2: 40 }, image: product2, description: 'Unlock your Tinder potential with our comprehensive guide! Learn how to optimize your profile and use proven strategies to increase your matches and connect with more people you find attractive.' },
    { id: 3, name: 'Matchbox Bundle', price: { USD2: 50 }, image: product3, description: 'Unlock your Tinder potential and master the art of communication with our ultimate bundle! This comprehensive guide will teach you how to optimize your profile, craft perfect messages, and use proven strategies to increase your matches, engage in meaningful conversations, and effortlessly secure more dates.' }
];

const AllProducts = () => {
    const { addToCart, openCart } = useCart();

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [currencyCode, setCurrencyCode] = useState('USD');
    const [exchangeRates, setExchangeRates] = useState({});

    useEffect(() => {
        const fetchLocationAndRates = async () => {
            try {
                const userCurrencyCode = await getUserLocation();
                setCurrencyCode(userCurrencyCode);
                const rates = await fetchExchangeRates();
                setExchangeRates(rates);
            } catch (error) {
                console.error('Failed to initialize currency settings', error);
            }
        };

        fetchLocationAndRates();
    }, []);

    const convertPrices = (product) => {
        const priceInUSD = product.price.USD2 || product.price;
        const discountedPrice = applyDiscount(priceInUSD, 20);
        const convertedOriginalPrice = convertPrice(priceInUSD, currencyCode, exchangeRates).toFixed(2);
        const convertedDiscountedPrice = convertPrice(discountedPrice, currencyCode, exchangeRates).toFixed(2);
        return {
            original: convertedOriginalPrice,
            discounted: convertedDiscountedPrice
        };
    };

    const handleAddToCart = (product) => {
        addToCart(product);
        openCart();
    };

    const openProductModal = (product) => {
        setSelectedProduct({
            ...product,
            prices: convertPrices(product)
        });
    };

    const closeProductCardModal = () => {
        setSelectedProduct(null);
    };

    return (
        <div className="wrapper_all_products">
            <div className="qwe">
                <span>We help men dominate Tinder</span>
            </div>
            <div className="all_products">
                <div className="product">
                    {products.map((product) => {
                        const prices = convertPrices(product);
                        return (
                            <div key={product.id} className="card">
                                <div className="image_section">
                                    <img src={product.image} alt="Product" onClick={() => openProductModal(product)} />
                                </div>
                                <div className="card_footer">
                                    <span className="item1" onClick={() => openProductModal(product)}>{product.name}</span>
                                    <span className="item2">
                                        {prices.discounted} {currencyCode}
                                        <s>{prices.original} {currencyCode}</s>
                                    </span>
                                    <ButtonAdd
                                        onClick={() => handleAddToCart(product)}
                                    >
                                        Add to card
                                    </ButtonAdd>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            {selectedProduct && (
                <ProductCardModal product={selectedProduct} onClose={closeProductCardModal} />
            )}
        </div>
    );
};

export default AllProducts;
