import { useState } from 'react';

export const useModal = () => {
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);
  const [isRefundPolicyOpen, setIsRefundPolicyOpen] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);

  const openModal = (type) => {
    switch(type) {
      case 'privacyPolicy':
        setIsPrivacyPolicyOpen(true);
        break;
      case 'refundPolicy':
        setIsRefundPolicyOpen(true);
        break;
      case 'termsOfService':
        setIsTermsOpen(true);
        break;
      default:
        break;
    }
  };

  const closeModal = (type) => {
    switch(type) {
      case 'privacyPolicy':
        setIsPrivacyPolicyOpen(false);
        break;
      case 'refundPolicy':
        setIsRefundPolicyOpen(false);
        break;
      case 'termsOfService':
        setIsTermsOpen(false);
        break;
      default:
        break;
    }
  };

  return {
    isPrivacyPolicyOpen,
    isRefundPolicyOpen,
    isTermsOpen,
    openModal,
    closeModal,
  };
};

export const handleReloadAndScrollUp = (e) => {
  e.preventDefault();
  window.scrollTo(0, 0); // Scroll to top
  window.location.reload(); // Reload page
};
