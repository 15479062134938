import React from "react";
import './buttonAdd.scss';

export default function ButtonAdd({ children, onClick }) {


    return (
        <button 
            className="btn-add btn"
            onClick={onClick}
        >
            {children}
        </button>
    );
}
