import React, { useEffect, useState } from 'react';
import './cart-modal.scss';
import './cart-modal-media.scss';
import iconClose from '../../home_page/img/iconClose.svg';
import iconPlus from '../../home_page/img/iconPlus.svg';
import iconMinus from '../../home_page/img/iconMinus.svg';
import { getUserLocation, fetchExchangeRates, convertPrice, applyDiscount } from '../../utils/Utils';
import ConfirmDialog from '../../modal-windows/confirm-remove-modal/ConfirmRemove';
import { useCart } from '../../cart/cart-context-section/Cart';
import ButtonAdd from '../../home_page/presentation/components/button-add/ButtonAdd';

const CartModal = ({ isOpen, onClose, products }) => {
    const { cartItems, removeFromCart, updateQuantity } = useCart();
    const [currencyCode, setCurrencyCode] = useState('USD');
    const [exchangeRates, setExchangeRates] = useState({});
    const [itemToRemove, setItemToRemove] = useState(null);

    useEffect(() => {
        const fetchLocationAndRates = async () => {
            try {
                const userCurrencyCode = await getUserLocation();
                setCurrencyCode(userCurrencyCode);
                const rates = await fetchExchangeRates();
                setExchangeRates(rates);
            } catch (error) {
                console.error('Failed to initialize currency settings', error);
            }
        };

        fetchLocationAndRates();
    }, []);

    const convertPrices = (product) => {
        const priceInUSD = product.price.USD2 || product.price;
        const discountedPrice = applyDiscount(priceInUSD, 20);
        const convertedOriginalPrice = convertPrice(priceInUSD, currencyCode, exchangeRates);
        const convertedDiscountedPrice = convertPrice(discountedPrice, currencyCode, exchangeRates);
        return {
            original: convertedOriginalPrice,
            discounted: convertedDiscountedPrice
        };
    };

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    if (!isOpen) return null;

    const handleQuantityChange = (id, value) => {
        if (value < 1) {
            setItemToRemove(id);
        } else {
            updateQuantity(id, value);
        }
    };

    const increment = (id, currentValue) => {
        updateQuantity(id, currentValue + 1);
    };

    const decrement = (id, currentValue) => {
        if (currentValue > 1) {
            updateQuantity(id, currentValue - 1);
        } else {
            setItemToRemove(id);
        }
    };

    const confirmRemove = () => {
        removeFromCart(itemToRemove);
        setItemToRemove(null);
    };

    const cancelRemove = () => {
        setItemToRemove(null);
    };

    const originalTotal = cartItems.reduce((total, item) => {
        const prices = convertPrices(item);
        return total + prices.original * item.quantity;
    }, 0);

    const discountedTotal = cartItems.reduce((total, item) => {
        const prices = convertPrices(item);
        return total + prices.discounted * item.quantity;
    }, 0);

    const discountTotal = originalTotal - discountedTotal;

    

    return (
        <div className="cart_modal_overlay" onClick={onClose}>
            <div className="cart_modal" onClick={(e) => e.stopPropagation()}>
                <div className="cart_name">
                    <button className="close_button" onClick={onClose}><img src={iconClose} alt="close" /></button>
                    <span>Cart • {cartItems.length} items</span>
                </div>
                {cartItems.length === 0 && <p>Cart is empty</p>}
                {cartItems.map(item => {
                    const prices = convertPrices(item);
                    return (
                        <div key={item.id} className="cart_item">
                            <div className="cart_item_section">
                                <div className="cart_image_section">
                                    <img src={item.image} alt={item.name} className="cart_item_image" />
                                </div>
                                <div className="product_description_section">
                                    <span className="items_name">{item.name} </span>
                                    <span className="items_description">{item.description}</span>
                                    <div className="discount_price_section">
                                        <span className="discount_name">Sale 20%</span>
                                        <div className="prices">
                                            <span className="original_price"><del>Original Price: {prices.original.toFixed(2)} {currencyCode}</del></span>
                                            <span className="price"> / {prices.discounted.toFixed(2)}{currencyCode}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="quantity_container">
                                <div className="counter">
                                    <button className="quantity_button_1" onClick={() => decrement(item.id, item.quantity)}>
                                        <img src={iconMinus} alt="-" />
                                    </button>
                                    <input
                                        type="number"
                                        value={item.quantity}
                                        onChange={(e) => handleQuantityChange(item.id, parseInt(e.target.value))}
                                        min="0"
                                        className="quantity"
                                    />
                                    <button className="quantity_button_2" onClick={() => increment(item.id, item.quantity)}>
                                        <img src={iconPlus} alt="+" />
                                    </button>
                                </div>
                                <button className="button_remove" onClick={() => setItemToRemove(item.id)}>Remove</button>
                            </div>
                        </div>
                    );
                })}
                <div className="middle_section">
                    {cartItems.length > 0 && (
                        <div className="cart_total">
                            <div className="saved_price section">
                                <span>Savings : </span>
                                <span>- {discountTotal.toFixed(2)} {currencyCode}</span>
                            </div>
                            <div className="total_price section">
                                <span>Subtotal : </span>
                                <span> {discountedTotal.toFixed(2)} {currencyCode}</span>
                            </div>
                        </div>
                    )}
                    <div className="checkout_section">
                        <ButtonAdd>
                            Checkout
                        </ButtonAdd>
                    </div>
                </div>
            </div>
            {itemToRemove !== null && (
                <ConfirmDialog
                    title="Confirm Removal"
                    message="Are you sure you want to remove this item from the cart?"
                    onConfirm={confirmRemove}
                    onCancel={cancelRemove}
                />
            )}
        </div>
    );
};

export default CartModal;

