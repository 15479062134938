import React, { useState, useEffect, useRef } from 'react';
import './result.scss';
import './result-media.scss';
import arrowLeft from '../../../img/arrowLeft.png';
import arrowRight from '../../../img/arrowRight.png';
import chat1 from '../../../img/screenChat.jpg';
import chat2 from '../../../img/chat2.jpg';
import chat3 from '../../../img/chat3.jpg';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


function Result() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [visibleSlides, setVisibleSlides] = useState(0);
    const images = [chat1, chat2, chat3, chat1, chat2, chat3, chat1, chat2];
    const sliderRef = useRef(null);

    useEffect(() => {
        const updateVisibleSlides = () => {
            const width = window.innerWidth;
            if (width >= 1024) {
                setVisibleSlides(4);
            } else if (width <= 480) {
                setVisibleSlides(2);
            } else {
                setVisibleSlides(1);
            }
        };

        updateVisibleSlides();
        window.addEventListener('resize', updateVisibleSlides);

        return () => {
            window.removeEventListener('resize', updateVisibleSlides);
        };
    }, []);

    useEffect(() => {
        document.documentElement.style.setProperty('--current-slide', currentSlide);
    }, [currentSlide]);

    const prevSlide = () => {
        setCurrentSlide((prev) => Math.max(prev - 1, 0));
    };

    const nextSlide = () => {
        setCurrentSlide((prev) => Math.min(prev + 1, images.length - visibleSlides));
    };

    const settings = {
        speed: 300,
        arrows: false,
        responsive: [
            {
                breakpoint: 3900,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                }
            }

        ]
    };

    return (
        <div className="wrapper_result">
            <div className="guaranteed_result">
                <span>Guaranteed Results</span>
            </div>
            <div className="result_pic_section">
                <div className="arrows">
                    <button className="arrowLeft arrow" onClick={() => sliderRef.current.slickPrev()}>
                        <img src={arrowLeft} alt="Previous Slide" />
                    </button>
                    <button
                        className="arrowRight arrow"
                        onClick={() => sliderRef.current.slickNext()}
                    >
                        <img src={arrowRight} alt="Next Slide" />
                    </button>
                </div>
                <div className="slider-wrapper">
                    <div className="slider">
                        <Slider {...settings} ref={sliderRef}>
                            {images.map((image, index) => (
                                <div key={index} className="pic">
                                    <img src={image} alt={`Slide ${index + 1}`} />
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Result;
