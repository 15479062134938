import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from '../src/modules/home_page/presentation/components/home-page/HomePage';


function App() {
  return (
    <Router>
      <div className="App">
        <div className="Main">
          <Routes>
            <Route path="*" element={<HomePage />} id="app"/>
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
